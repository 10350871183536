import React from 'react';
import i18n from 'i18next';

import {
  AgilityButton,
  AgilityGrid,
  AgilitySelectField,
  AgilityTextField,
  AgilityCard,
  AgilityTypography,
} from 'Src/AgilityComponents';
import {
  ID_NUMBER_MAX_LENGTH,
  ID_NUMBER_MIN_LENGTH,
} from 'App/utils/fieldLengthHelper';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { array, object, number, bool } from 'prop-types';
import {
  isAlphaNumericOnly,
  isNotEmpty,
  validateFields,
} from 'App/utils/validationHelper';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { convertToDateOnly } from 'App/utils/helper';
import { Element, scroller } from 'react-scroll';
import {
  showDirectDebitStep,
  showBillingStep,
  businessIdentificationOptional,
} from 'App/customConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const defaultField = {
  identificationType: '',
  identificationNumber: '',
  identificationExpiry: null,
};
const Identification = props => {
  const {
    saveProgressTrigger,
    isActive,
    signupAPIResponse,
    onNavigate,
    idTypeList,
    openProgressDialog,
    isAgentView,
  } = props;
  const [fields, setFields] = React.useState({ ...defaultField });
  const [errors, setErrors] = React.useState({
    identificationType: '',
    identificationNumber: '',
    identificationExpiry: '',
  });
  const [touched, setTouched] = React.useState({
    identificationType: false,
    identificationNumber: false,
    identificationExpiry: false,
  });

  const [expiryError, setExpiryError] = React.useState('');
  const prevPropRef = React.useRef();
  const [isOptionalExipry, setIsOptionalExipry] = React.useState(false);
  const [isOptionalForm, setIsOptionalForm] = React.useState(false);

  const validators = {
    defaultChecker: isNotEmpty,
    fields: {
      identificationType: {
        preCondition: '',
        defaultErrorText: 'identification.errorMessage.requiredtypeid',
        conditions: [],
        isOptional: isOptionalForm,
      },
      identificationNumber: {
        preCondition: '',
        defaultErrorText: 'identification.errorMessage.requiredidnumber',
        conditions: [
          {
            condition: isAlphaNumericOnly,
            errorText: 'identification.errorMessage.invalidnumber',
          },
          {
            condition: val => val.length >= ID_NUMBER_MIN_LENGTH,
            errorText: 'identification.errorMessage.invalidnumber',
          },
        ],
        isOptional: isOptionalForm,
      },
      identificationExpiry: {
        preCondition: '',
        defaultErrorText: 'identification.errorMessage.requiredexpiry',
        conditions: [],
        isOptional: isOptionalExipry || isOptionalForm,
      },
    },
  };

  const handleChange = (val, name) => {
    touched[name] = true;
    fields[name] = val;
    setTouched(touched);
    setFields(fields);
    validateForm();
  };

  const onExpiryError = error => {
    setExpiryError(error);
  };

  const nextForm = e => {
    for (const key in touched) {
      touched[key] = true;
    }
    setTouched(touched);
    const invalidField = validateForm();
    if (!invalidField && !expiryError) {
      const data = { ...signupAPIResponse };
      data.identifications = [fields];

      if (showDirectDebitStep) {
        data.currentPage = signupSteps.paymentMethod;
      }
      // Payment menthod is not appear for agent view, next to payment detail
      if (isAgentView) {
        data.currentPage = signupSteps.paymentDetails;
      }
      if (!showDirectDebitStep && showBillingStep) {
        data.currentPage = signupSteps.paymentDetails;
      }
      if (!showDirectDebitStep && !showBillingStep) {
        data.currentPage = signupSteps.lifeSupportConcession;
      }

      onNavigate('next', data, false);
    } else {
      setTimeout(() => {
        scroller.scrollTo(invalidField, {
          duration: 1000,
          delay: 10,
          smooth: 'easeInOutQuart',
          isDynamic: true,
          top: 0,
          offset: -220, // Scrolls to element + 220 pixels down the page
        });
      }, 300);
    }
  };

  const validateForm = () => {
    const formValidationData = validateFields(validators, fields, touched);
    setErrors(formValidationData.errorMessages);
    return formValidationData.invalidField;
  };

  const onBackClick = () => {
    resetValidation();
    props.onNavigate('back');
  };

  const resetValidation = React.useCallback(() => {
    for (const key in touched) {
      touched[key] = false;
    }
    setErrors({});
  }, [touched]);

  const setExpiryDateOptional = (value, input) => {
    const { expiryDateRequired } =
      idTypeList.find(x => x.value === value) || {};
    setIsOptionalExipry(!!expiryDateRequired);
    handleChange(value, input);
  };

  React.useEffect(() => {
    if (
      signupAPIResponse.identifications &&
      signupAPIResponse.identifications.length > 0
    ) {
      setFields(signupAPIResponse.identifications[0]);
    } else {
      setFields({ ...defaultField });
    }

    if (
      businessIdentificationOptional &&
      signupAPIResponse.propertyType === 'COMPANY'
    ) {
      setIsOptionalForm(true);
    }
  }, [signupAPIResponse]);

  React.useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = { ...signupAPIResponse };
      data.identifications = [fields];
      data.currentPage = signupSteps.yourIdentification;
      onNavigate('progress', data, true);
    }
    prevPropRef.current = saveProgressTrigger;
    if (isActive === false) {
      resetValidation();
    }
  }, [
    saveProgressTrigger,
    isActive,
    fields,
    onNavigate,
    signupAPIResponse,
    resetValidation,
  ]);

  return (
    <AgilityCard className={`steps-wrapper ${props.className}`} id={props.id}>
      <AgilityTypography variant="h4" component="h4" className="mb-2">
        {i18n.t('identification.header.text')}
      </AgilityTypography>
      <form autoComplete="off" noValidate data-test-id="identificationForm">
        <AgilityGrid container spacing={2}>
          <AgilityGrid item xs={12} sm={6}>
            <Element name="identificationType">
              <AgilitySelectField
                disabled={!isActive}
                options={idTypeList}
                data-test-id="identificationType"
                variant="outlined"
                fullWidth
                value={fields.identificationType || ''}
                showlabel={true}
                placeholder={`${i18n.t('identification.placeholder.typeid')}${
                  isOptionalForm ? '' : ' *'
                }`}
                onChange={event =>
                  setExpiryDateOptional(
                    event.target.value,
                    'identificationType'
                  )
                }
                isError={Boolean(errors && errors.identificationType)}
                helperText={
                  errors && errors.identificationType
                    ? errors.identificationType
                    : ''
                }
                MenuProps={{
                  PaperProps: { square: true },
                }}
              />
            </Element>
          </AgilityGrid>
          <AgilityGrid item xs={12} sm={6}>
            <Element name="identificationNumber">
              <AgilityTextField
                disabled={!isActive}
                id="identificationNumber"
                data-test-id="identificationNumber"
                type="text"
                fullWidth
                placeholder={i18n.t('identification.placeholder.idnumber')}
                value={fields.identificationNumber || ''}
                onChange={val => handleChange(val, 'identificationNumber')}
                variant="outlined"
                required={!isOptionalForm}
                inputProps={{ maxLength: ID_NUMBER_MAX_LENGTH }}
                error={Boolean(errors && errors.identificationNumber)}
                helperText={
                  errors && errors.identificationNumber
                    ? errors.identificationNumber
                    : ''
                }
              />
            </Element>
          </AgilityGrid>
          <AgilityGrid item xs={12} sm={6}>
            <div className="text-field-wrapper">
              <Element name="identificationExpiry">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={!isActive}
                    data-test-id="identificationExpiry"
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format={window.dateFormat}
                    id="date-picker-inline"
                    label={i18n.t('identification.placeholder.expiry', {
                      dateFormat: window.dateFormat.toUpperCase(),
                    })}
                    value={fields.identificationExpiry || null}
                    onChange={val =>
                      handleChange(
                        convertToDateOnly(val),
                        'identificationExpiry'
                      )
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                    disablePast={true}
                    minDateMessage={i18n.t(
                      'identification.errorMessage.minDateExpiry'
                    )}
                    onError={onExpiryError}
                    required={!isOptionalExipry && !isOptionalForm}
                    helperText={
                      errors && errors.identificationExpiry
                        ? errors.identificationExpiry
                        : expiryError
                        ? expiryError
                        : ''
                    }
                    error={
                      (errors && errors.identificationExpiry) || expiryError
                        ? true
                        : false
                    }
                  />
                </MuiPickersUtilsProvider>
              </Element>
            </div>
          </AgilityGrid>
        </AgilityGrid>
        <div className="steps-footer">
          {isAgentView && (
            <AgilityButton
              color="primary"
              onClick={openProgressDialog}
              label={i18n.t('saveprogress.sms.button')}
              data-test-id="smsButton"
              disabled={!isActive}
              endIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
            />
          )}
          <AgilityButton
            disabled={!isActive}
            color="primary"
            label={i18n.t('signup.button.back')}
            onClick={() => {
              onBackClick();
            }}
            data-test-id="backButton"
            className="push"
          />
          <AgilityButton
            disabled={!isActive}
            variant="contained"
            loading={props.nextLoading}
            color="primary"
            type="primary"
            label={i18n.t('signup.button.next')}
            data-test-id="nextButton"
            onClick={e => {
              nextForm(e);
            }}
          />
        </div>
      </form>
    </AgilityCard>
  );
};
Identification.propTypes = {
  idTypeList: array,
  signupAPIResponse: object,
  saveProgressTrigger: number,
  isActive: bool,
};
Identification.defaultProps = {
  idTypeList: [],
  isActive: false,
};
export default Identification;
