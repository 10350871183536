import React from 'react';
import i18n from 'i18next';
import { Element } from 'react-scroll';
import DateFnsUtils from '@date-io/date-fns';
import dompurify from 'isomorphic-dompurify';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
  AgilityCheckbox,
  AgilityFormGroup,
  AgilityGrid,
  AgilitySelectField,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import {
  CARD_NUMBER_MAX_LENGTH,
  CARDHOLDER_NAME_MAX_LENGTH,
} from 'App/utils/fieldLengthHelper';
import { convertToDateOnly } from 'App/utils/helper';
import AgilityDialog from 'App/components/Dialog/AgilityDialog';
import { Alert } from '@material-ui/lab';
import { logSaleAction } from 'App/utils/geolocation';
import { ACCOUNT_ID } from 'App/utils/constants';

const sanitizer = dompurify.sanitize;

const ConcessionCardForm = ({
  isTangoPartner,
  cardList,
  changeDate,
  onStartDateError,
  startDateError,
  minCardExpiry,
  onExpiryDateError,
  expiryDateError,
  customerState,
  fields,
  setFields,
  handleChange,
  isActive,
  hasError,
  errors,
  isAgentView,
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const concessionConsentPopupBody = React.useMemo(() => {
    let contentPath = 'lifeSupportConcession.consent.popup.body';

    switch (customerState) {
      case 'VIC':
      case 'NSW':
        contentPath += '.nsw';
        break;
      case 'QLD':
        contentPath += '.qld';
        break;
      case 'TAS':
        contentPath += '.tas';
        break;
      case 'SA':
      default:
        contentPath = '';
    }

    return contentPath;
  }, [customerState]);

  const department = React.useMemo(() => {
    let result = 'unspecified';
    if (customerState === 'QLD') {
      result = 'Department of ';

      switch (fields.cardType) {
        case 'DVAGC':
        case 'DVAGC_TPI':
        case 'DVAGC_WW':
          result += 'Veteran Affairs';
          break;
        case 'PCC':
        case 'HCC':
          result += 'Human Services';
          break;
        case 'IMMI':
          result += 'Immigration and Border Protection';
          break;
        case 'QGSC':
          result += 'Communities, Disability Services and Seniors';
          break;
        default:
          result += 'undefined';
      }
    }
    return result;
  }, [customerState, fields.cardType]);

  const acceptConsent = React.useCallback(() => {
    setOpenDialog(false);
    setFields(props => ({
      ...props,
      hasConsented: true,
    }));
    logSaleAction(
      {
        accountId: localStorage.getItem(ACCOUNT_ID),
      },
      'ACCEPT_CONCESSION_DECLARATION',
      true
    );
  }, [setFields]);

  const rejectConsent = React.useCallback(() => {
    setOpenDialog(false);
    setFields(props => ({
      ...props,
      hasConcessionCard: false,
    }));
  }, [setFields]);

  const setConsent = React.useCallback(
    status => {
      if (isAgentView) {
        return;
      }
      if (status) {
        setOpenDialog(status);
        return;
      }
      setFields(props => ({
        ...props,
        hasConsented: status,
      }));
    },
    [isAgentView, setFields]
  );

  if (isTangoPartner) {
    if (customerState === 'QLD') {
      return (
        <div data-test-id="concession-card-form-alt-text">
          <AgilityTypography
            variant="subtitle2"
            component="p"
            className="mb-2"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('lifeSupportConcession.concessionCard.formAltText.qld', {
                  interpolation: { escapeValue: false },
                }),
                { ADD_ATTR: ['target'] }
              ),
            }}
          />
        </div>
      );
    }

    if (customerState === 'SA') {
      return (
        <div data-test-id="concession-card-form-alt-text">
          <AgilityTypography
            variant="subtitle2"
            component="p"
            className="mb-2"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('lifeSupportConcession.concessionCard.formAltText.sa', {
                  interpolation: { escapeValue: false },
                }),
                { ADD_ATTR: ['target'] }
              ),
            }}
          />
        </div>
      );
    }
  }

  if (customerState === 'SA') {
    return (
      <div data-test-id="concession-card-alert">
        <Alert severity="info">
          {i18n.t('lifeSupportConcession.concessionCard.alertText')}
        </Alert>
      </div>
    );
  }

  return (
    <div data-test-id="concession-card-fields">
      <AgilityTypography variant="subtitle2" component="p" className="mb-2">
        {i18n.t('lifeSupportConcession.concessionCard.text')}
      </AgilityTypography>

      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={12} sm={6}>
          <Element name="cardName">
            <AgilityTextField
              id="cardHolderName"
              data-test-id="cardHolderName"
              type="text"
              fullWidth
              placeholder={i18n.t(
                'lifeSupportConcession.placeholder.cardHolderName'
              )}
              value={fields.cardName || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'cardName')}
              variant="outlined"
              required
              error={hasError('cardName')}
              helperText={hasError('cardName') ? errors.cardName : ''}
              inputProps={{ maxLength: CARDHOLDER_NAME_MAX_LENGTH }}
            />
          </Element>
        </AgilityGrid>

        <AgilityGrid item xs={12} sm={6}>
          <Element name="cardLastName">
            <AgilityTextField
              id="cardHolderLastName"
              data-test-id="cardHolderLastName"
              type="text"
              fullWidth
              placeholder={i18n.t(
                'lifeSupportConcession.placeholder.cardHolderLastName'
              )}
              value={fields.cardLastName || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'cardLastName')}
              variant="outlined"
              required
              error={hasError('cardLastName')}
              helperText={hasError('cardLastName') ? errors.cardLastName : ''}
              inputProps={{ maxLength: CARDHOLDER_NAME_MAX_LENGTH }}
            />
          </Element>
        </AgilityGrid>
      </AgilityGrid>

      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={12} sm={6}>
          <Element name="cardType">
            <AgilitySelectField
              data-test-id="cardType"
              options={cardList || []}
              variant="outlined"
              fullWidth
              labelId="demo-simple-select-filled-label"
              value={fields.cardType || ''}
              disabled={!isActive}
              showlabel
              placeholder={i18n.t('lifeSupportConcession.placeholder.cardType')}
              onChange={event => handleChange(event.target.value, 'cardType')}
              isError={hasError('cardType')}
              helperText={hasError('cardType') ? errors.cardType : ''}
            />
          </Element>
        </AgilityGrid>
        <AgilityGrid item xs={12} sm={6}>
          <Element name="cardNumber">
            <AgilityTextField
              id="cardNumber"
              data-test-id="cardNumber"
              type="text"
              fullWidth
              placeholder={i18n.t(
                'lifeSupportConcession.placeholder.cardNumber'
              )}
              value={fields.cardNumber || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'cardNumber')}
              variant="outlined"
              required
              error={hasError('cardNumber')}
              helperText={hasError('cardNumber') ? errors.cardNumber : ''}
              inputProps={{ maxLength: CARD_NUMBER_MAX_LENGTH }}
            />
          </Element>
        </AgilityGrid>
      </AgilityGrid>

      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={12} sm={6}>
          <div className="text-field-wrapper">
            <Element name="cardStartDate">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  id="cardStartDate"
                  label={i18n.t('lifeSupportConcession.placeholder.startDate')}
                  value={fields.cardStartDate || null}
                  disabled={!isActive}
                  data-test-id="card-start-date"
                  onChange={val =>
                    handleChange(convertToDateOnly(val), 'cardStartDate')
                  }
                  KeyboardButtonProps={{
                    'aria-label': changeDate,
                  }}
                  autoOk
                  onError={onStartDateError}
                  required
                  helperText={
                    hasError('cardStartDate') || startDateError
                      ? errors.cardStartDate || startDateError
                      : ''
                  }
                  error={
                    hasError('cardStartDate') || startDateError ? true : false
                  }
                />
              </MuiPickersUtilsProvider>
            </Element>
          </div>
        </AgilityGrid>
        <AgilityGrid item xs={12} sm={6}>
          <div className="text-field-wrapper">
            <Element name="cardExpiry">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  id="cardExpiryDate"
                  label={i18n.t('lifeSupportConcession.placeholder.expiryDate')}
                  value={fields.cardExpiry || null}
                  disabled={!isActive}
                  data-test-id="card-expiry-date"
                  onChange={val =>
                    handleChange(convertToDateOnly(val), 'cardExpiry')
                  }
                  KeyboardButtonProps={{
                    'aria-label': changeDate,
                  }}
                  autoOk
                  minDate={minCardExpiry}
                  minDateMessage={i18n.t(
                    'lifeSupportConcession.validCardExpirationDate.errorMessage'
                  )}
                  onError={onExpiryDateError}
                  helperText={
                    hasError('cardExpiry') || expiryDateError
                      ? errors.cardExpiry || expiryDateError
                      : ''
                  }
                  error={
                    hasError('cardExpiry') || expiryDateError ? true : false
                  }
                />
              </MuiPickersUtilsProvider>
            </Element>
          </div>
        </AgilityGrid>
      </AgilityGrid>

      <div data-test-id="concession-card-centerlink">
        <AgilityGrid container spacing={2}>
          <AgilityGrid item xs={12} sm={12}>
            <AgilityFormGroup row className="custom-checkbox-row">
              <Element name="hasConsented">
                <AgilityCheckbox
                  id="hasConsented"
                  className="custom"
                  data-test-id="hasConsented"
                  checked={!!fields['hasConsented']}
                  color="primary"
                  onChange={event => setConsent(event.target.checked)}
                  disabled={isAgentView}
                />
              </Element>
              <AgilityTypography
                variant="subtitle1"
                component="p"
                color="textSecondary"
                className={hasError('hasConsented') ? 'has-error' : ''}
                onClick={() => setConsent(!fields['hasConsented'])}
              >
                {i18n.t(
                  'lifeSupportConcession.checkbox.centrelink.detailVerified1',
                  {
                    retailer: process.env.REACT_APP_COPYRIGHT_COMPANY,
                  }
                )}
                {i18n.t('lifeSupportConcession.link.centrelink') || ''}
                {i18n.t(
                  'lifeSupportConcession.checkbox.centrelink.detailVerified2'
                )}
              </AgilityTypography>
            </AgilityFormGroup>
          </AgilityGrid>
        </AgilityGrid>
      </div>

      <AgilityDialog
        data-test-id="concessionConfirmDialog"
        className="confirmation-dialog"
        open={openDialog}
        okClick={acceptConsent}
        noClick={rejectConsent}
        showDeclineButton
        declineText={i18n.t('dialog.decline')}
        onDismiss={() => setOpenDialog(false)}
        maxWidth="md"
        title={i18n.t('lifeSupportConcession.consent.popup.heading')}
        okText={i18n.t('dialog.accept')}
        contents={[
          {
            id: 'consent-content-text',
            body: i18n.t(concessionConsentPopupBody, {
              name: `${fields.firstName} ${fields.lastName}`,
              department,
              retailer: process.env.REACT_APP_COPYRIGHT_COMPANY,
              interpolation: { escapeValue: false },
            }),
            variant: 'subtitle1',
          },
        ]}
      />
    </div>
  );
};

export default ConcessionCardForm;
