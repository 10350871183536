/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import {
  AgilityButton,
  AgilityCard,
  AgilityRadioGroup,
  AgilitySelectField,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import i18n from 'i18next';
import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import { propertyTypes } from 'App/pages/ComparePlans/data';
import {
  getListForSelectField,
  getProductTypeByProductCode,
} from 'App/utils/helper';
import { POST_CODE_MAX_LENGTH } from 'App/utils/fieldLengthHelper';
import { func } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  isNotEmpty,
  isPostcode,
  validateFields,
} from 'App/utils/validationHelper';
import { fetchDistributors } from 'App/pages/ComparePlans/action';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PRODUCT, productsMap } from 'App/utils/constants';
import { useSnackbar } from 'notistack';

const initialState = {
  fuelType: '',
  propertyType: '',
  postcode: '',
  planOfferId: '',
  elecPlanId: '',
  gasPlanId: '',
};

const TABS = {
  PLAN_DOCUMENT: {
    VALUE: 'PLAN_DOCUMENT',
    FIELDS: ['fuelType', 'propertyType', 'elecPlanId', 'gasPlanId', 'postcode'],
  },
  PLAN_OFFER_ID: {
    VALUE: 'PLAN_OFFER_ID',
    FIELDS: ['planOfferId', 'postcode'],
  },
};

const FactSheet = props => {
  const theme = useTheme();
  const {
    fetchOfferList,
    fetchProductList,
    fetchPropertyList,
    allPlans,
    getOfferDocuments,
    getFactSheet,
    productList,
    propertyList,
  } = props;
  const [tabValue, setTabValue] = useState(TABS.PLAN_DOCUMENT.VALUE);
  const [factsheets, setFactsheets] = useState([]);
  const [loadingFactsheets, setLoadingFactsheets] = useState(false);
  const [elecPlanOptions, setElecPlanOptions] = useState([]);
  const [gasPlanOptions, setGasPlanOptions] = useState([]);
  const [loadingPlanOptions, setLoadingPlanOptions] = useState(false);
  const [loadedPlanOptions, setLoadedPlanOptions] = useState(false); // Triggers final updates when we have options
  const [distributors, setDistributors] = useState([]);
  const [noneFound, setNoneFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [fields, setFields] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [touched, setTouched] = useState(initialState);

  const productOptions = getListForSelectField(productList, true);

  const validators = {
    defaultChecker: isNotEmpty,
    fields: {
      fuelType: {
        isOptional: tabValue !== TABS.PLAN_DOCUMENT.VALUE,
        defaultErrorText: 'factsheets.errorMessage.fuelTypeRequired',
        conditions: [],
      },
      propertyType: {
        isOptional: tabValue !== TABS.PLAN_DOCUMENT.VALUE,
        defaultErrorText: 'factsheets.errorMessage.propertyTypeRequired',
        conditions: [],
      },
      elecPlanId: {
        isOptional:
          tabValue !== TABS.PLAN_DOCUMENT.VALUE || elecPlanOptions.length === 0,
        defaultErrorText: 'factsheets.errorMessage.planIdRequired',
        conditions: [],
      },
      gasPlanId: {
        isOptional:
          tabValue !== TABS.PLAN_DOCUMENT.VALUE || gasPlanOptions.length === 0,
        defaultErrorText: 'factsheets.errorMessage.planIdRequired',
        conditions: [],
      },
      planOfferId: {
        isOptional: tabValue !== TABS.PLAN_OFFER_ID.VALUE,
        defaultErrorText: 'factsheets.errorMessage.planOfferIdRequired',
        conditions: [],
      },
      postcode: {
        isOptional: false,
        defaultErrorText: 'factsheets.errorMessage.postcodeRequired',
        conditions: [
          {
            condition: isPostcode,
            errorText: 'factsheets.errorMessage.postcodeInvalid',
          },
        ],
      },
    },
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setFactsheets([]);
    setNoneFound(false);
  };

  const handleChange = (val, name) => {
    setFields({ ...fields, [name]: val });
    setTouched({ ...touched, [name]: true });
    setFactsheets([]);
    setNoneFound(false);
  };

  useEffect(() => {
    Promise.all([fetchProductList(), fetchPropertyList()])
      .then(() => {})
      .catch(err => {
        enqueueSnackbar(i18n.t('500.error.message'), {
          variant: 'error',
        });
      });
  }, []);

  useEffect(() => {
    if (productList.length === 1) {
      setFields({ ...fields, fuelType: productList[0].code });
    }
  }, [productList]);

  useEffect(() => {
    if (propertyList.length === 1) {
      setFields({ ...fields, propertyType: propertyList[0].value });
    }
  }, [propertyList]);

  /**
   * Get distributor
   */
  useEffect(() => {
    // Check if they're not empty without validating, so we don't get errors just yet
    if (fields.fuelType === '' || fields.postcode === '') {
      return;
    }
    // Check valid postcode
    setTouched({ ...touched, postcode: true });
    let valid = validateForm();
    if (valid) {
      // Clean up options
      setElecPlanOptions([]);
      setGasPlanOptions([]);

      setLoadingPlanOptions(true);
      setLoadedPlanOptions(false);
      fetchDistributors(fields.postcode).then(result => {
        if (result.length === 0) {
          setNoneFound(true);
          setLoadingPlanOptions(false);
          return;
        }
        let newDistributors = [];
        result.forEach(distributor => {
          let fuelType =
            productsMap.POWER === parseInt(distributor.fuel_id)
              ? PRODUCT.ELECTRICITY
              : PRODUCT.GAS;
          if (
            fields.fuelType === PRODUCT.BOTH ||
            fuelType === fields.fuelType
          ) {
            newDistributors.push(distributor);
          }
        });
        setDistributors(newDistributors);
      });
    }
  }, [fields.postcode, fields.fuelType]);

  /**
   * Update plan options when propertyType is filled in, and we have a distributor
   */
  useEffect(() => {
    // Check if they're not empty without validating, so we don't get errors just yet
    if (
      fields.fuelType === '' ||
      fields.propertyType === '' ||
      fields.postcode === '' ||
      distributors.length <= 0
    ) {
      return;
    }

    // Check valid postcode
    setTouched({ ...touched, postcode: true });
    let valid = validateForm();
    if (valid) {
      setLoadingPlanOptions(true);
      setLoadedPlanOptions(false);
      setElecPlanOptions([]);
      setGasPlanOptions([]);
      setFields({ ...fields, elecPlanId: '', gasPlanId: '' });

      let promises = [];
      let propertyTypeValue = getProductTypeByProductCode(
        propertyTypes,
        fields.propertyType
      ).value;
      distributors.forEach(distributor => {
        let fuelType =
          productsMap.POWER === parseInt(distributor.fuel_id)
            ? PRODUCT.ELECTRICITY
            : PRODUCT.GAS;
        const data = {
          distributor_id: distributor.id,
          market_segment_id: propertyTypeValue,
          postcode: fields.postcode,
          api_code: process.env.REACT_APP_API_USER_ID,
          includeSolar: false,
        };
        let promise = Promise.resolve(fetchOfferList(null, data, fuelType));
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        // Done all our API calls, but we still need react to follow through and update state,
        // so set a flag to trigger final updates (errors, reset loading)
        setLoadedPlanOptions(true);
      });
    }
  }, [fields.propertyType, distributors]);

  /**
   * After fetchOfferList() has updated allPlans, update plan options
   */
  useEffect(() => {
    if (fields.fuelType === '') {
      return;
    }
    let elecOptions = [];
    let gasOptions = [];

    // allPlans will be updated multiple times as fetchOfferList() is used for each distributor above
    // So don't overwrite or duplicate existing values
    if (
      fields.fuelType === PRODUCT.ELECTRICITY ||
      fields.fuelType === PRODUCT.BOTH
    ) {
      if (allPlans[PRODUCT.ELECTRICITY]) {
        allPlans[PRODUCT.ELECTRICITY].forEach(plan => {
          if (!elecPlanOptions.find(x => x.value === plan.id)) {
            elecOptions.push({ value: plan.id, label: plan.name });
          }
        });
      }
    }
    if (fields.fuelType === PRODUCT.GAS || fields.fuelType === PRODUCT.BOTH) {
      if (allPlans[PRODUCT.GAS]) {
        allPlans[PRODUCT.GAS].forEach(plan => {
          if (!gasPlanOptions.find(x => x.value === plan.id)) {
            gasOptions.push({ value: plan.id, label: plan.name });
          }
        });
      }
    }

    setElecPlanOptions([...elecPlanOptions, ...elecOptions]);
    setGasPlanOptions([...gasPlanOptions, ...gasOptions]);
  }, [allPlans]);

  /**
   * This is the end of the chain of hooks required to update plan options. Reset loading and add errors here
   */
  useEffect(() => {
    // Clear errors here, just to make sure any we set now are the only errors we'll have
    setErrors({ ...errors, elecPlanId: '' });
    setErrors({ ...errors, gasPlanId: '' });

    if (distributors.length > 0 && loadedPlanOptions) {
      if (
        (fields.fuelType === PRODUCT.BOTH ||
          fields.fuelType === PRODUCT.ELECTRICITY) &&
        elecPlanOptions.length === 0
      ) {
        setErrors({
          ...errors,
          elecPlanId: i18n.t('factsheets.errorMessage.noPlansFound', {
            fuel: 'electricity',
          }),
        });
      }

      if (
        (fields.fuelType === PRODUCT.BOTH || fields.fuelType === PRODUCT.GAS) &&
        gasPlanOptions.length === 0
      ) {
        setErrors({
          ...errors,
          gasPlanId: i18n.t('factsheets.errorMessage.noPlansFound', {
            fuel: 'gas',
          }),
        });
      }

      setLoadingPlanOptions(false);
    }
  }, [elecPlanOptions, gasPlanOptions, loadedPlanOptions]);

  // as fields change, validate the form and search.
  useEffect(() => {
    let valid = validateForm();
    if (!valid) {
      setLoadingFactsheets(false);
      return;
    }

    if (!loadingFactsheets) {
      return;
    }

    // do search
    if (
      tabValue === TABS.PLAN_OFFER_ID.VALUE &&
      /[a-zA-Z]/g.test(fields.planOfferId) // A plan ID will have letters, an offer ID won't.
    ) {
      getFactSheet({
        postcode: fields.postcode,
        identifier: fields.planOfferId, // plan ID
      })
        .then(result => {
          setLoadingFactsheets(false);
          if (result.length === 0) {
            setNoneFound(true);
            return;
          }
          setFactsheets([result]);
        })
        .catch(error => {
          setLoadingFactsheets(false);
          setNoneFound(true);
        });
      return;
    }

    let offer_ids = [];
    if (tabValue === TABS.PLAN_DOCUMENT.VALUE) {
      if (fields.elecPlanId) {
        offer_ids.push(fields.elecPlanId);
      }
      if (fields.gasPlanId) {
        offer_ids.push(fields.gasPlanId);
      }
    } else {
      offer_ids = [fields.planOfferId];
    }

    let promises = [];
    offer_ids.forEach(offer_id => {
      let promise = Promise.resolve(
        getOfferDocuments({
          postcode_id: fields.postcode,
          offer_id: offer_id,
        })
      );
      promises.push(promise);
    });

    Promise.all(promises).then(results => {
      setLoadingFactsheets(false);
      let factsheets = results.flat(1);
      if (factsheets.length === 0) {
        setNoneFound(true);
        return;
      }
      setFactsheets(factsheets);
    });
  }, [fields, touched, loadingFactsheets]);

  const validateForm = () => {
    const formValidationData = validateFields(validators, fields, touched);
    setErrors(formValidationData.errorMessages);
    return Object.keys(formValidationData.errorMessages).length === 0;
  };

  const onSearchClick = () => {
    // Set fields in the current tab to touched, so the form is validated.
    // Adding every field will cause errors to appear when you switch tab and start editing fields, and we may miss
    // hooks, like to get the distributor
    const updateTouched = [];
    TABS[tabValue].FIELDS.forEach(field => {
      updateTouched[field] = true;
    });
    setFactsheets([]);
    setNoneFound(false);
    setTouched(updateTouched);
    setLoadingFactsheets(true);
  };

  const onClearClick = () => {
    setFields(initialState);
    setTouched(initialState);
    setErrors(initialState);
    setFactsheets([]);
    setElecPlanOptions([]);
    setGasPlanOptions([]);
    setNoneFound(false);

    let defaultFields = {
      fuelType: initialState.fuelType,
      propertyType: initialState.propertyType,
    };
    if (productList.length === 1) {
      defaultFields.fuelType = productList[0].code;
    }
    if (propertyList.length === 1) {
      defaultFields.propertyType = propertyList[0].value;
    }
    setFields({
      ...initialState,
      fuelType: defaultFields.fuelType,
      propertyType: defaultFields.propertyType,
    });
  };

  return (
    <div css={theme.custom.CenteredPageCss}>
      <Container maxWidth={'md'}>
        <Helmet>
          <title>{`Energy Fact Sheet Information | ${process.env.REACT_APP_RETAILER_VALUE}`}</title>
        </Helmet>
        <AgilityCard className="agent-login mt-2 mb-2">
          <Container>
            <AgilityTypography variant="h3" align="center" className="mb-2">
              {i18n.t('factsheet.header')}
            </AgilityTypography>
            <AgilityTypography variant="h6" component="h6">
              {i18n.t('factsheet.subHeader')}
            </AgilityTypography>

            <TabContext value={tabValue}>
              <Box>
                <TabList onChange={handleTabChange}>
                  <Tab
                    label={i18n.t('factsheet.tabs.planDocument')}
                    value={TABS.PLAN_DOCUMENT.VALUE}
                    css={theme.custom.FactSheet.Tab}
                  />
                  <Tab
                    label={i18n.t('factsheet.tabs.planOfferId')}
                    value={TABS.PLAN_OFFER_ID.VALUE}
                    css={theme.custom.FactSheet.Tab}
                  />
                </TabList>
              </Box>
              <TabPanel value={TABS.PLAN_DOCUMENT.VALUE}>
                <AgilityTypography
                  variant="body2"
                  component="p"
                  className="mb-1"
                >
                  {i18n.t('factsheet.fuelType.label')}
                </AgilityTypography>
                <AgilityRadioGroup
                  className="two-line"
                  options={productOptions}
                  onChange={event => {
                    handleChange(event.target.value, 'fuelType');
                  }}
                  value={fields.fuelType || ''}
                  row
                  isError={errors && errors.fuelType ? true : false}
                  helperText={errors && errors.fuelType ? errors.fuelType : ''}
                  css={theme.custom.FactSheet.RadioGroup}
                />

                <AgilityTypography
                  variant="body2"
                  component="p"
                  className="mb-1"
                >
                  {i18n.t('factsheet.propertyType.label')}
                </AgilityTypography>
                <AgilityRadioGroup
                  className="two-line"
                  options={propertyList}
                  onChange={event => {
                    handleChange(event.target.value, 'propertyType');
                  }}
                  value={fields.propertyType || ''}
                  row
                  isError={errors && errors.propertyType ? true : false}
                  helperText={
                    errors && errors.propertyType ? errors.propertyType : ''
                  }
                  css={theme.custom.FactSheet.RadioGroup}
                />
              </TabPanel>

              <TabPanel value={TABS.PLAN_OFFER_ID.VALUE}>
                <AgilityTypography
                  variant="body2"
                  component="p"
                  className="mb-1"
                >
                  {i18n.t('factsheet.planOfferId.label')}
                </AgilityTypography>
                <AgilityTextField
                  id="planOfferId"
                  type="text"
                  placeholder={i18n.t('factsheet.planOfferId.placeholder')}
                  value={fields.planOfferId || ''}
                  onChange={val => handleChange(val, 'planOfferId')}
                  variant="outlined"
                  required={true}
                  error={errors && errors.planOfferId ? true : false}
                  helperText={
                    errors && errors.planOfferId ? errors.planOfferId : ''
                  }
                />
              </TabPanel>

              <div
                css={css`
                  padding: 0 0.75rem;
                  padding-bottom: 0.75rem;
                `}
              >
                <AgilityTypography
                  variant="body2"
                  component="p"
                  className="mb-1"
                >
                  {i18n.t('factsheet.postcode.label')}
                </AgilityTypography>
                <AgilityTextField
                  id="postcode"
                  type="text"
                  placeholder={i18n.t('factsheet.postcode.placeholder')}
                  value={fields.postcode || ''}
                  onChange={val => handleChange(val, 'postcode')}
                  variant="outlined"
                  inputProps={{
                    maxLength: POST_CODE_MAX_LENGTH,
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                  }}
                  required={true}
                  error={errors && errors.postcode ? true : false}
                  helperText={errors && errors.postcode ? errors.postcode : ''}
                />

                {((Object.keys(elecPlanOptions).length > 0 &&
                  !loadingPlanOptions) ||
                  errors.elecPlanId) &&
                  tabValue === TABS.PLAN_DOCUMENT.VALUE && (
                    <>
                      <AgilityTypography
                        variant="body2"
                        component="p"
                        className="mb-1"
                      >
                        {i18n.t('factsheet.plan.label', {
                          fuel:
                            fields.fuelType === PRODUCT.BOTH
                              ? 'electricity'
                              : '',
                        })}
                      </AgilityTypography>
                      <AgilitySelectField
                        options={elecPlanOptions}
                        variant="outlined"
                        fullWidth
                        value={fields.elecPlanId || ''}
                        showlabel={true}
                        placeholder={i18n.t('factsheet.plan.placeholder')}
                        onChange={event =>
                          handleChange(event.target.value, 'elecPlanId')
                        }
                        isError={errors && errors.elecPlanId ? true : false}
                        helperText={
                          errors && errors.elecPlanId ? errors.elecPlanId : ''
                        }
                      />
                    </>
                  )}

                {((Object.keys(gasPlanOptions).length > 0 &&
                  !loadingPlanOptions) ||
                  errors.gasPlanId) &&
                  tabValue === TABS.PLAN_DOCUMENT.VALUE && (
                    <>
                      <AgilityTypography
                        variant="body2"
                        component="p"
                        className="mb-1"
                      >
                        {i18n.t('factsheet.plan.label', {
                          fuel: fields.fuelType === PRODUCT.BOTH ? 'gas' : '',
                        })}
                      </AgilityTypography>
                      <AgilitySelectField
                        options={gasPlanOptions}
                        variant="outlined"
                        fullWidth
                        value={fields.gasPlanId || ''}
                        showlabel={true}
                        placeholder={i18n.t('factsheet.plan.placeholder')}
                        onChange={event =>
                          handleChange(event.target.value, 'gasPlanId')
                        }
                        isError={errors && errors.gasPlanId ? true : false}
                        helperText={
                          errors && errors.gasPlanId ? errors.gasPlanId : ''
                        }
                      />
                    </>
                  )}

                <Grid container className="mb-1">
                  <AgilityButton
                    loading={
                      loadingPlanOptions &&
                      tabValue === TABS.PLAN_DOCUMENT.VALUE
                    }
                    disabled={
                      !fields.elecPlanId &&
                      !fields.gasPlanId &&
                      tabValue === TABS.PLAN_DOCUMENT.VALUE
                    }
                    onClick={onSearchClick}
                    label={i18n.t('button.search.label')}
                    variant="contained"
                    color="primary"
                    size="large"
                    type="primary"
                    css={css`
                      margin-right: 10px;
                    `}
                  />
                  <AgilityButton
                    onClick={onClearClick}
                    label="Clear"
                    variant="contained"
                    size="large"
                  />
                </Grid>
              </div>
            </TabContext>

            {loadingFactsheets && (
              <div
                className="loader-section"
                style={{
                  position: 'relative',
                  marginTop: '20px',
                }}
              >
                <CircularProgress
                  color="primary"
                  variant="indeterminate"
                  className="loader"
                  thickness={3}
                  size={30}
                />
              </div>
            )}

            {noneFound && tabValue === TABS.PLAN_DOCUMENT.VALUE && (
              <p>{i18n.t('factsheet.planDocument.noneFound')}</p>
            )}
            {noneFound && tabValue === TABS.PLAN_OFFER_ID.VALUE && (
              <p>{i18n.t('factsheet.planOfferId.noneFound')}</p>
            )}

            {factsheets.map((data, index) => (
              <div id={index} key={index}>
                <AgilityButton
                  href={data.url}
                  target="_blank"
                  label={
                    data.distributor +
                    ': ' +
                    data.retail_tariff +
                    ' - ' +
                    data.energy_plan
                  }
                  variant="contained"
                  fullWidth={true}
                  size="medium"
                  css={css`
                    margin-top: 15px;
                  `}
                  buttonCss={css`
                    justify-content: flex-start;
                  `}
                  endIcon={<FontAwesomeIcon icon={['fas', 'file-alt']} />}
                />
              </div>
            ))}
          </Container>
        </AgilityCard>
      </Container>
    </div>
  );
};

FactSheet.propTypes = {
  getOfferDocuments: func,
  getFactSheet: func,
  fetchProductList: func,
  fetchPropertyList: func,
};
FactSheet.defaultProps = {};

export default FactSheet;
