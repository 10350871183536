import React, { useState, useEffect, useRef } from 'react';
import i18n from 'i18next';
import { func, bool } from 'prop-types';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  AgilityInfoDialog,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import {
  isValidEmail,
  isValidMobilePhoneNumber,
  validateFields,
  isValidNameWithSpaceAndSymbol,
  isNotEmpty,
} from 'App/utils/validationHelper';
import { convertToDateOnly } from 'App/utils/helper';
import { EMAIL_MAX_LENGTH } from 'App/utils/fieldLengthHelper';

const SaveProgressDialog = props => {
  const [fields, setFields] = useState({
    value: props.progressData.value,
    name: props.progressData.name,
    dateOfBirth: props.progressData.dateOfBirth,
  });
  const [errors, setErrors] = useState({
    value: '',
    name: '',
    dateOfBirth: '',
  });
  const [touched, setTouched] = useState({
    value: false,
    name: false,
    dateOfBirth: false,
  });

  const {
    open,
    saveProgressLoading,
    onSaveProgress,
    saveProgressIsSuccess,
    isEmail,
  } = props;
  const targetType = isEmail ? 'email' : 'tel';

  const prevPropRef = useRef();

  const validators = {
    defaultChecker: isNotEmpty,
    fields: {
      value: {
        preCondition: '',
        defaultErrorText: isEmail
          ? 'saveprogress.errorMessage.requiredEmail'
          : 'saveprogress.errorMessage.requiredPhone',
        conditions: [
          {
            condition: isEmail ? isValidEmail : isValidMobilePhoneNumber,
            errorText: isEmail
              ? 'saveprogress.errorMessage.invalidEmail'
              : 'saveprogress.errorMessage.invalidPhone',
          },
        ],
      },
      name: {
        preCondition: '',
        defaultErrorText: 'saveprogress.errorMessage.requiredName',
        conditions: [
          {
            condition: isValidNameWithSpaceAndSymbol,
            errorText: 'saveprogress.errorMessage.invalidName',
          },
        ],
      },
      dateOfBirth: {
        preCondition: '',
        defaultErrorText: 'saveprogress.errorMessage.requiredDateOfBirth',
        conditions: [],
      },
    },
  };

  const maxDob = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  );

  useEffect(() => {
    if (
      prevPropRef.current &&
      prevPropRef.current !== saveProgressLoading &&
      !saveProgressLoading &&
      saveProgressIsSuccess
    ) {
      onSaveProgress();
    }
    prevPropRef.current = saveProgressLoading;
  }, [saveProgressLoading, onSaveProgress, saveProgressIsSuccess]);

  const handleChange = (val, name) => {
    touched[name] = true;
    fields[name] = val;
    setTouched(touched);
    setFields(fields);
    validateForm();
  };

  useEffect(() => {
    fields['value'] = props.progressData.value;
    fields['name'] = props.progressData.name;
    fields['dateOfBirth'] = props.progressData.dateOfBirth;
    setFields(fields);
  }, [fields, props.progressData]);

  const validateForm = () => {
    const formValidationData = validateFields(validators, fields, touched);
    setErrors(formValidationData.errorMessages);
    return formValidationData.invalidField === '';
  };

  const saveProgressClick = () => {
    touched['value'] = true;
    touched['name'] = true;
    touched['dateOfBirth'] = true;
    setTouched(touched);
    if (validateForm()) {
      props.saveProgressTrigger(fields);
    } else {
      console.log('form not valid');
    }
  };

  const onCloseClick = () => {
    if (fields['value'] === '') {
      errors['value'] = '';
    }
    props.onDismiss();
  };

  return (
    <AgilityInfoDialog
      disableBackdropClick
      data-test-id="save-progress-dialog"
      className="confirmation-dialog save-progress-dialog full-page-popup-sm"
      fullWidth
      maxWidth="xs"
      open={open}
      title={i18n.t('saveprogress.heading.text')}
      titleAlign="center"
      okText={i18n.t('saveprogress.save.button')}
      okClick={saveProgressClick}
      closeClick={onCloseClick}
      okLoading={props.saveProgressLoading}
    >
      <form autoComplete="off">
        <AgilityTypography
          data-test-id="contract-text"
          variant="subtitle1"
          component="p"
          className="pb-2"
        >
          {i18n.t('saveprogress.subheading.text', {
            type: isEmail ? 'email' : 'SMS',
          })}
        </AgilityTypography>
        <div className="mb-3">
          <AgilityTextField
            data-test-id="save-progress-name"
            fullWidth
            placeholder={i18n.t(`saveprogress.name.placeholder`)}
            defaultValue={fields.name}
            onChange={val => handleChange(val, 'name')}
            variant="outlined"
            required={true}
            error={errors && errors.name ? true : false}
            helperText={errors && errors.name ? errors.name : ''}
            inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
          />
        </div>
        <div className="mb-3">
          <AgilityTextField
            data-test-id="save-progress-value"
            type={targetType}
            fullWidth
            placeholder={i18n.t(`saveprogress.${targetType}.placeholder`)}
            defaultValue={fields.value || ''}
            onChange={val => handleChange(val, 'value')}
            variant="outlined"
            required={true}
            error={errors && errors.value ? true : false}
            helperText={errors && errors.value ? errors.value : ''}
            inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
          />
        </div>
        <div className="mb-3">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              variant="inline"
              inputVariant="outlined"
              format={window.dateFormat}
              id="primaryDob"
              label={i18n.t('yourDetail.placeholder.dob', {
                dateFormat: window.dateFormat.toUpperCase(),
              })}
              value={fields.dateOfBirth || null}
              // disabled={!isActive}
              data-test-id="primaryDob"
              onChange={val =>
                handleChange(convertToDateOnly(val), 'dateOfBirth')
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              autoOk
              maxDate={maxDob}
              maxDateMessage={i18n.t('yourDetail.errorMessage.maxDateDob')}
              onError={setErrors['dateOfBirth']}
              required
              helperText={
                errors && errors.dateOfBirth ? errors.dateOfBirth : ''
              }
              error={errors && errors.dateOfBirth ? true : false}
            />
          </MuiPickersUtilsProvider>
        </div>
      </form>
    </AgilityInfoDialog>
  );
};

SaveProgressDialog.propTypes = {
  onSaveProgress: func,
  onDismiss: func,
  open: bool,
  saveProgressTrigger: func,
  saveProgressLoading: bool,
  saveProgressIsSuccess: bool,
  isEmail: bool,
};
SaveProgressDialog.defaultProps = {
  open: false,
  saveProgressIsSuccess: false,
  saveProgressLoading: false,
  isEmail: true,
};
export default SaveProgressDialog;
