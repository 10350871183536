import React from 'react';
import i18n from 'i18next';
import { Element } from 'react-scroll';
import dompurify from 'isomorphic-dompurify';

import {
  AgilityTypography,
  AgilityRadioGroup,
  AgilityGrid,
  AgilityInfo,
} from 'Src/AgilityComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noYesOptions, yesNoOptionsWithBooleanValue } from 'App/utils/data';
import ConcessionCardForm from './ConcessionCardForm';
import { disableSignupsForMedicalCoolingCustomers } from 'App/customConfig';

const sanitizer = dompurify.sanitize;

const ConcessionCard = ({
  isTangoPartner,
  fields,
  errors,
  cardList,
  isActive,
  handleChange,
  changeDate,
  onStartDateError,
  startDateError,
  minCardExpiry,
  onExpiryDateError,
  expiryDateError,
  setFields,
  isResident,
  isEmbeddedNetwork,
  isAgentView,
}) => {
  const {
    hasConcessionCard,
    hasCoolingConcession,
    state: customerState,
  } = fields;

  const showConcessionCardForm = React.useMemo(
    () => hasConcessionCard === true && isResident,
    [hasConcessionCard, isResident]
  );

  const showConcessionCardQuestion = React.useMemo(
    () => isResident && !isEmbeddedNetwork,
    [isEmbeddedNetwork, isResident]
  );

  const showCoolingCardQuestion = React.useMemo(
    () => showConcessionCardForm && ['ACT', 'SA'].indexOf(customerState) === -1,
    [customerState, showConcessionCardForm]
  );

  const coolingLabel = React.useMemo(() => {
    let labelPath = 'lifeSupportConcession.header.medicalCoolingConcession';

    switch (customerState) {
      case 'SA':
        labelPath += '.sa';
        break;
      case 'VIC':
        labelPath += '.vic';
        break;
      case 'NSW':
        labelPath += '.nsw';
        break;
      case 'QLD':
        labelPath += '.qld';
        break;
      default:
        labelPath += '';
    }

    return i18n.t(labelPath);
  }, [customerState]);

  const hasError = React.useCallback(
    field => errors && errors.hasOwnProperty(field) && errors[field] !== '',
    [errors]
  );

  return (
    <AgilityGrid container>
      {showConcessionCardQuestion && (
        <AgilityGrid item xs={12}>
          <div data-test-id="concession-card-option">
            <div className="question-wrapper">
              <AgilityTypography
                variant="body2"
                component="p"
                tooltipText={i18n.t('lifeSupportConcession.concessionCardInfo')}
                showInfo
              >
                {i18n.t('lifeSupportConcession.header.concessionCard')}
              </AgilityTypography>
              <Element name="hasConcessionCard">
                <AgilityRadioGroup
                  options={yesNoOptionsWithBooleanValue}
                  onChange={event => {
                    handleChange(event.target.value, 'hasConcessionCard');
                  }}
                  data-test-id="isConcessionCard"
                  value={hasConcessionCard}
                  disabled={!isActive}
                  row
                  isError={hasError('hasConcessionCard')}
                  helperText={
                    hasError('hasConcessionCard')
                      ? errors.hasConcessionCard
                      : ''
                  }
                />
              </Element>
            </div>
          </div>
        </AgilityGrid>
      )}
      {showConcessionCardForm && (
        <ConcessionCardForm
          {...{
            isTangoPartner,
            cardList,
            changeDate,
            onStartDateError,
            startDateError,
            minCardExpiry,
            onExpiryDateError,
            expiryDateError,
            customerState,
            fields,
            setFields,
            handleChange,
            isActive,
            hasError,
            errors,
            isAgentView,
          }}
        />
      )}
      {showCoolingCardQuestion && (
        <AgilityGrid item xs={12} data-test-id="concession-cooling-card-option">
          <div className="question-wrapper">
            <AgilityTypography variant="body2" component="p">
              {coolingLabel}
            </AgilityTypography>
            <Element name="hasCoolingConcession">
              <AgilityRadioGroup
                options={noYesOptions}
                onChange={event => {
                  handleChange(event.target.value, 'hasCoolingConcession');
                }}
                data-test-id="hasCoolingConcession"
                value={hasCoolingConcession}
                disabled={!isActive}
                row
                isError={hasError('hasCoolingConcession')}
                helperText={
                  hasError('hasCoolingConcession')
                    ? errors.hasCoolingConcession
                    : ''
                }
              />
            </Element>
          </div>
          {hasCoolingConcession === 'yes' &&
            isTangoPartner &&
            (customerState === 'NSW' || customerState === 'VIC') && (
              <AgilityTypography
                variant="subtitle2"
                component="p"
                className="mb-2"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    i18n.t(
                      `lifeSupportConcession.concessionCoolingCard.text.${customerState.toLowerCase()}`,
                      {
                        interpolation: { escapeValue: false },
                      }
                    ),
                    { ADD_ATTR: ['target'] }
                  ),
                }}
              />
            )}
          {hasCoolingConcession === 'yes' &&
            disableSignupsForMedicalCoolingCustomers && (
              <AgilityGrid item xs={12} sm={12}>
                <AgilityInfo
                  severity="warning"
                  color="warning"
                  className="alert-wrapper"
                  icon={
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                  }
                >
                  <AgilityTypography
                    variant="body2"
                    component="p"
                    className="mt-0"
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(
                        'lifeSupportConcession.lifeSupportPersonDetail.warning.description',
                        {
                          phoneNumber: process.env.REACT_APP_COMPANY_PHONE,
                        }
                      ),
                    }}
                  ></AgilityTypography>
                </AgilityInfo>
              </AgilityGrid>
            )}
        </AgilityGrid>
      )}
    </AgilityGrid>
  );
};

export default ConcessionCard;
