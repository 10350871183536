import {
  DATE_FORMAT_USER,
  PRODUCT,
  TANGO_GAS_BILL_CYCLE_CODES,
  TANGO_POWER_BILL_CYCLE_CODES,
} from './constants';
import { scroller } from 'react-scroll';
import { format, isValid, parseISO } from 'date-fns';
import cookie from 'js-cookie';
import { referAFriend } from '../customConfig';
import { productTypes } from 'App/pages/ComparePlans/data';

const dateFormat1 = 'dd/MM/yyyy';
const dateFormat2 = 'dd-MM-yyyy';
const dateFormat3 = 'dd.MM.yyyy';
const dateFormat4 = 'yyyy/MM/dd';
const dateFormat5 = 'yyyy-MM-dd';
const dateFormat6 = 'd. M. yyyy';
const dateFormat7 = 'MM/dd/yyyy';

const VICTORIAN_FACT_SHEET_NAME = 'Energy Fact Sheet';
const NEM_FACT_SHEET_NAME = 'Basic Plan Information Document';

const VICTORIAN_BENCHMARK_NAME = 'Victorian Default Offer';
const NEM_BENCHMARK_NAME = 'reference price';

export default function getListApiUrl(apiUrl, data) {
  let listApiUrl = `${apiUrl}`;
  let key;
  let flag = true;
  for (key in data) {
    if (data[key] !== undefined && data[key] !== '') {
      const value = encodeURIComponent(data[key]);
      if (flag) {
        listApiUrl += `?${key}=${value}`;
        flag = false;
      } else {
        listApiUrl += `&${key}=${value}`;
      }
    }
  }
  return listApiUrl;
}

// Retrieves isSuccess property from response object.
export function isSuccess({ isSuccess = false }) {
  return isSuccess;
}

export function getResponseMessage({ messages = [] }) {
  if (messages.length > 0) {
    const [message] = messages;
    return message || 'Error';
  }
  return '';
}

export const getDistributorByProductCode = (distributorList, productCode) => {
  const product = productTypes.find(
    productType => productType.code === productCode
  );
  const distributors = distributorList.filter(
    distributor => parseInt(distributor.fuel_id) === product.value
  );

  if (isEmpty(distributors)) {
    return; // if the distributors is empty, don't display the red errors // UC-1970
  }

  return distributors[0];
};

export const getProductTypeByProductCode = (productList, productCode) => {
  return productList.find(x => x.code === productCode);
};

export const getListForSelectField = (arr, byCode = false) => {
  if (!Array.isArray(arr)) {
    return [];
  }
  return arr.map(obj => ({
    label: obj.display,
    value: byCode ? obj.code : obj.value,
    id: obj.id,
    icon: obj.icon,
    checkedIcon: obj.checkedIcon,
  }));
};

export const getListForSelectFieldByKeys = obj => {
  const keys = Object.keys(obj);

  return keys.map(key => ({
    label: key,
    value: key,
  }));
};

export const searchFunction = input => {
  let i, txtValue;
  const filter = input.toUpperCase();
  const ul = document.getElementById('selectionList');
  const li = ul.getElementsByTagName('li');
  let totalDisplayedNone = 0;

  for (i = 0; i < li.length; i++) {
    txtValue = li[i].innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      li[i].style.display = '';
    } else {
      totalDisplayedNone++;
      li[i].style.display = 'none';
    }
  }
  if (totalDisplayedNone === li.length) {
    document.getElementById('no-option-available').style.display = 'block';
  } else {
    document.getElementById('no-option-available').style.display = 'none';
  }
};

export const getCurrentTimestamp = () => {
  return Date.now();
};

export const convertDateFromISO = value => {
  const d = new Date(value);
  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${year}-${month}-${day}`;
};

export const convertDateFromISOArray = arr => {
  if (!Array.isArray(arr)) {
    return [];
  }
  const productArr = arr.map(value => convertDateFromISO(value));
  return productArr;
};

export const getListUsageCategories = arr => {
  if (!Array.isArray(arr)) {
    return [];
  }
  const newArr = arr.map(obj => ({
    label: obj.name,
    value: obj.code.toString(),
    icon: obj.icon,
    people: obj.description,
    unit: obj.descriptionAlternative,
  }));
  return newArr;
};

export const getLocaleDateFormat = locale => {
  let formats = {
    'ar-SA': 'dd/MM/yy',
    'bg-BG': 'dd.M.yyyy',
    'ca-ES': dateFormat1,
    'zh-TW': 'yyyy/M/d',
    'cs-CZ': 'd.M.yyyy',
    'da-DK': dateFormat2,
    'de-DE': dateFormat3,
    'el-GR': 'd/M/yyyy',
    'en-US': 'M/d/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fr-FR': dateFormat1,
    'he-IL': dateFormat1,
    'hu-HU': 'yyyy. MM. dd.',
    'is-IS': 'd.M.yyyy',
    'it-IT': dateFormat1,
    'ja-JP': dateFormat4,
    'ko-KR': dateFormat5,
    'nl-NL': 'd-M-yyyy',
    'nb-NO': dateFormat3,
    'pl-PL': dateFormat5,
    'pt-BR': 'd/M/yyyy',
    'ro-RO': dateFormat3,
    'ru-RU': dateFormat3,
    'hr-HR': 'd.M.yyyy',
    'sk-SK': dateFormat6,
    'sq-AL': dateFormat5,
    'sv-SE': dateFormat5,
    'th-TH': 'd/M/yyyy',
    'tr-TR': dateFormat3,
    'ur-PK': dateFormat1,
    'id-ID': dateFormat1,
    'uk-UA': dateFormat3,
    'be-BY': dateFormat3,
    'sl-SI': 'd.M.yyyy',
    'et-EE': 'd.MM.yyyy',
    'lv-LV': 'yyyy.MM.dd.',
    'lt-LT': 'yyyy.MM.dd',
    'fa-IR': dateFormat7,
    'vi-VN': dateFormat1,
    'hy-AM': dateFormat3,
    'az-Latn-AZ': dateFormat3,
    'eu-ES': dateFormat4,
    'mk-MK': dateFormat3,
    'af-ZA': dateFormat4,
    'ka-GE': dateFormat3,
    'fo-FO': dateFormat2,
    'hi-IN': dateFormat2,
    'ms-MY': dateFormat1,
    'kk-KZ': dateFormat3,
    'ky-KG': 'dd.MM.yy',
    'sw-KE': 'M/d/yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'tt-RU': dateFormat3,
    'pa-IN': 'dd-MM-yy',
    'gu-IN': 'dd-MM-yy',
    'ta-IN': dateFormat2,
    'te-IN': 'dd-MM-yy',
    'kn-IN': 'dd-MM-yy',
    'mr-IN': dateFormat2,
    'sa-IN': dateFormat2,
    'mn-MN': 'yy.MM.dd',
    'gl-ES': 'dd/MM/yy',
    'kok-IN': dateFormat2,
    'syr-SY': dateFormat1,
    'dv-MV': 'dd/MM/yy',
    'ar-IQ': dateFormat1,
    'zh-CN': 'yyyy/M/d',
    'de-CH': dateFormat3,
    'en-GB': dateFormat1,
    'es-MX': dateFormat1,
    'fr-BE': dateFormat1,
    'it-CH': dateFormat3,
    'nl-BE': dateFormat1,
    'nn-NO': dateFormat3,
    'pt-PT': dateFormat2,
    'sr-Latn-CS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'az-Cyrl-AZ': dateFormat3,
    'ms-BN': dateFormat1,
    'uz-Cyrl-UZ': dateFormat3,
    'ar-EG': dateFormat1,
    'zh-HK': 'd/M/yyyy',
    'de-AT': dateFormat3,
    'en-AU': dateFormat1,
    'es-ES': dateFormat1,
    'fr-CA': dateFormat5,
    'sr-Cyrl-CS': 'd.M.yyyy',
    'ar-LY': dateFormat1,
    'zh-SG': 'd/M/yyyy',
    'de-LU': dateFormat3,
    'en-CA': dateFormat1,
    'es-GT': dateFormat1,
    'fr-CH': dateFormat3,
    'ar-DZ': dateFormat2,
    'zh-MO': 'd/M/yyyy',
    'de-LI': dateFormat3,
    'en-NZ': dateFormat1,
    'es-CR': dateFormat1,
    'fr-LU': dateFormat1,
    'ar-MA': dateFormat2,
    'en-IE': dateFormat1,
    'es-PA': dateFormat7,
    'fr-MC': dateFormat1,
    'ar-TN': dateFormat2,
    'en-ZA': dateFormat4,
    'es-DO': dateFormat1,
    'ar-OM': dateFormat1,
    'en-JM': dateFormat1,
    'es-VE': dateFormat1,
    'ar-YE': dateFormat1,
    'en-029': dateFormat7,
    'es-CO': dateFormat1,
    'ar-SY': dateFormat1,
    'en-BZ': dateFormat1,
    'es-PE': dateFormat1,
    'ar-JO': dateFormat1,
    'en-TT': dateFormat1,
    'es-AR': dateFormat1,
    'ar-LB': dateFormat1,
    'en-ZW': 'M/d/yyyy',
    'es-EC': dateFormat1,
    'ar-KW': dateFormat1,
    'en-PH': 'M/d/yyyy',
    'es-CL': dateFormat2,
    'ar-AE': dateFormat1,
    'es-UY': dateFormat1,
    'ar-BH': dateFormat1,
    'es-PY': dateFormat1,
    'ar-QA': dateFormat1,
    'es-BO': dateFormat1,
    'es-SV': dateFormat1,
    'es-HN': dateFormat1,
    'es-NI': dateFormat1,
    'es-PR': dateFormat1,
    'am-ET': 'd/M/yyyy',
    'tzm-Latn-DZ': dateFormat2,
    'iu-Latn-CA': dateFormat1,
    'sma-NO': dateFormat3,
    'mn-Mong-CN': 'yyyy/M/d',
    'gd-GB': dateFormat1,
    'en-MY': 'd/M/yyyy',
    'prs-AF': 'dd/MM/yy',
    'bn-BD': 'dd-MM-yy',
    'wo-SN': dateFormat1,
    'rw-RW': 'M/d/yyyy',
    'qut-GT': dateFormat1,
    'sah-RU': 'MM.dd.yyyy',
    'gsw-FR': dateFormat1,
    'co-FR': dateFormat1,
    'oc-FR': dateFormat1,
    'mi-NZ': dateFormat1,
    'ga-IE': dateFormat1,
    'se-SE': dateFormat5,
    'br-FR': dateFormat1,
    'smn-FI': 'd.M.yyyy',
    'moh-CA': 'M/d/yyyy',
    'arn-CL': dateFormat2,
    'ii-CN': 'yyyy/M/d',
    'dsb-DE': dateFormat6,
    'ig-NG': 'd/M/yyyy',
    'kl-GL': dateFormat2,
    'lb-LU': dateFormat1,
    'ba-RU': 'dd.MM.yy',
    'nso-ZA': dateFormat4,
    'quz-BO': dateFormat1,
    'yo-NG': 'd/M/yyyy',
    'ha-Latn-NG': 'd/M/yyyy',
    'fil-PH': 'M/d/yyyy',
    'ps-AF': 'dd/MM/yy',
    'fy-NL': 'd-M-yyyy',
    'ne-NP': 'M/d/yyyy',
    'se-NO': dateFormat3,
    'iu-Cans-CA': 'd/M/yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'si-LK': dateFormat5,
    'sr-Cyrl-RS': 'd.M.yyyy',
    'lo-LA': dateFormat1,
    'km-KH': dateFormat5,
    'cy-GB': dateFormat1,
    'bo-CN': 'yyyy/M/d',
    'sms-FI': 'd.M.yyyy',
    'as-IN': dateFormat2,
    'ml-IN': 'dd-MM-yy',
    'en-IN': dateFormat2,
    'or-IN': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'tk-TM': 'dd.MM.yy',
    'bs-Latn-BA': 'd.M.yyyy',
    'mt-MT': dateFormat1,
    'sr-Cyrl-ME': 'd.M.yyyy',
    'se-FI': 'd.M.yyyy',
    'zu-ZA': dateFormat4,
    'xh-ZA': dateFormat4,
    'tn-ZA': dateFormat4,
    'hsb-DE': dateFormat6,
    'bs-Cyrl-BA': 'd.M.yyyy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'sr-Latn-BA': 'd.M.yyyy',
    'smj-NO': dateFormat3,
    'rm-CH': dateFormat1,
    'smj-SE': dateFormat5,
    'quz-EC': dateFormat1,
    'quz-PE': dateFormat1,
    'hr-BA': 'd.M.yyyy.',
    'sr-Latn-ME': 'd.M.yyyy',
    'sma-SE': dateFormat5,
    'en-SG': 'd/M/yyyy',
    'ug-CN': 'yyyy-M-d',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'es-US': 'M/d/yyyy',
  };

  return formats[locale] || DATE_FORMAT_USER;
};

/**
 * @description returns a url with replaced parameter values
 * @param {url} string
 * @param {params} object
 * @example replaceUrlParams('api/{accountNo}/{vendor}', { accountNo, vendor })
 */
export function replaceUrlParams(url, params) {
  let finalUrl = url;
  for (const [key, value] of Object.entries(params)) {
    finalUrl = finalUrl.replace(`{${key}}`, value);
  }
  return finalUrl;
}

export const filterOffering = (offerList, usage, frequency) => {
  const offers = offerList.reduce((result, obj) => {
    if (obj.productOfferingEstimates) {
      const estimate = filterEstimates(
        obj.productOfferingEstimates,
        usage,
        frequency
      );
      if (estimate) {
        const offer = { ...obj, productOfferingEstimate: estimate };
        result.push(offer);
      }
    }
    return result;
  }, []);
  return offers;
};

export const filterEstimates = (productOfferingEstimates, usage, frequency) => {
  const estimate = productOfferingEstimates.find(
    x => x.usageCategory === usage
  );
  let newEstimate;
  if (estimate) {
    newEstimate = { ...estimate };
    newEstimate.estimatedCost = getEstimatedCost(
      estimate.estimatedCost,
      frequency
    );
    newEstimate.period = getPeriodAbbreviation(frequency);
  }
  return newEstimate;
};
export const getEstimatedCost = (cost, frequency) => {
  let period;
  switch (frequency) {
    case 'FORTNIGHTLY':
      period = 26;
      break;
    case 'MONTHLY':
      period = 12;
      break;
    case 'QUARTERLY':
      period = 4;
      break;
    case 'YEARLY':
    default:
      period = 12;
      break;
  }
  const finalcost = cost / period;
  return finalcost.toFixed(2);
};

export const getPeriodAbbreviation = frequency => {
  let abbreviation;
  switch (frequency) {
    case 'FORTNIGHTLY':
      abbreviation = 'fortn';
      break;
    case 'MONTHLY':
      abbreviation = 'mo';
      break;
    case 'QUARTERLY':
      abbreviation = 'qtly';
      break;
    default:
      abbreviation = '';
      break;
  }
  return abbreviation;
};

export const getStateFromId = id => {
  const stateMap = {
    1: 'WA',
    2: 'NT',
    3: 'QLD',
    4: 'SA',
    5: 'NSW',
    6: 'ACT',
    7: 'VIC',
    8: 'TAS',
  };
  return stateMap[id] || '';
};

export const customMask = validFormat => {
  const digitRegExp = /\d/;
  const array = validFormat.split('');
  const formatArr = array.map(value => (value !== '-' ? digitRegExp : '-'));
  return formatArr;
};

export const convertToDateOnly = val => {
  if (val instanceof Date) {
    if (isValid(val)) {
      return format(val, 'yyyy-MM-dd');
    }
  }

  return isValid(parseISO(val)) ? format(parseISO(val), 'yyyy-MM-dd') : val;
};

export const maskCardDetails = val => {
  if (!val) return;
  return val.replace(/\./g, '●');
};

export const paymentIcons = {
  'cc-amex': 'fab',
  'credit-card': 'fas',
  'cc-mastercard': 'fab',
  'cc-visa': 'fab',
  'cc-diners-club': 'fab',
  'cc-discover': 'fab',
  'cc-jcb': 'fab',
  'hand-holding-usd': 'fas',
};

export const dataToMask = (value, mask = []) => {
  const [valueLength, maskLength] = [value.length, mask.length];
  if (valueLength > maskLength) {
    //valueLength always less or equal to maskLength
    return;
  }
  let counter = 0;

  const result = mask.reduce((acc, char) => {
    if (valueLength < counter + 1) {
      return acc;
    }
    if (char instanceof RegExp && char.test(value[counter])) {
      acc += value[counter];
      counter++;
    } else {
      acc += char;
    }
    return acc;
  }, '');

  return result;
};

export const isEmpty = value => {
  return (
    !value ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const setSaveProgressData = (
  progressData,
  bodyData,
  sites = [],
  property
) => {
  let data;
  const { name, value, dateOfBirth } = progressData;
  const signupAPIResponse = {
    ...bodyData,
    propertyType: property,
  };
  if (isEmpty(signupAPIResponse)) {
    data = {
      contacts: [
        {
          isPrimary: 1,
          firstName: name,
          dateOfBirth: dateOfBirth,
          phones: [
            {
              isPrimary: 1,
              phoneNumber: value,
              type: 'Mobile',
            },
          ],
        },
      ],
    };
  } else {
    const { contacts: [customer] = [{}] } = signupAPIResponse;
    if (isEmpty(customer)) {
      data = {
        ...signupAPIResponse,
        contacts: [
          {
            isPrimary: 1,
            firstName: name,
            dateOfBirth: dateOfBirth,
            phones: [
              {
                isPrimary: 1,
                phoneNumber: value,
                type: 'Mobile',
              },
            ],
          },
        ],
      };
    } else {
      const primaryIndex = signupAPIResponse.contacts.findIndex(
        c => c.isPrimary === 1
      );

      signupAPIResponse.contacts[primaryIndex].firstName = name;
      const [phone] = customer.phones;
      if (isEmpty(phone)) {
        signupAPIResponse.contacts[primaryIndex].phones = [
          {
            isPrimary: 1,
            phoneNumber: value,
            type: 'Mobile',
          },
        ];
      } else {
        const mobileIndex = signupAPIResponse.contacts[
          primaryIndex
        ].phones.findIndex(p => p.type === 'Mobile');

        if (mobileIndex > -1) {
          signupAPIResponse.contacts[primaryIndex].phones[
            mobileIndex
          ].phoneNumber = value;
        } else {
          signupAPIResponse.contacts[primaryIndex].phones.push({
            isPrimary: 1,
            phoneNumber: value,
            type: 'Mobile',
          });
        }
      }
      data = signupAPIResponse;
    }
  }
  if (isEmpty(signupAPIResponse.services)) {
    data.services = sites.map(_site => ({
      siteIdentifier: _site.identifier,
      productType: _site.productType,
      productOffering: {
        offeringCode: '',
        pricePlanCode: '',
        usageCategory: '',
        paymentFrequency: '',
        estimatedCost: 0,
      },
    }));
  }
  return data;
};

// String boolean type-safe converter
export const yesNoOptionConverter = data => {
  const dataType = typeof data;
  if (dataType === 'string') {
    if (data === 'not sure') {
      return null;
    }
    return data === 'yes';
  }
  return dataType !== 'boolean' ? 'not sure' : data ? 'yes' : 'no';
};

export const pfsDocumentName = state => {
  if (state === 'VIC' || state === '7') return VICTORIAN_FACT_SHEET_NAME;
  return NEM_FACT_SHEET_NAME;
};

export const referencePriceName = state => {
  if (state === 'VIC') return VICTORIAN_BENCHMARK_NAME;
  return NEM_BENCHMARK_NAME;
};

export const referenceDirection = percentInt => {
  return percentInt === 0 ? 'the' : 'than the';
};

export const scrollToSection = id => {
  setTimeout(() => {
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 10,
      smooth: 'easeInOutQuart',
      isDynamic: true,
      top: 0,
      offset: -220,
    });
  }, 300);
};

export const roundToDecimalGSTCents = (val, removeZeroTail = false) => {
  return roundToDecimalCents(val, removeZeroTail, false);
};

export const roundToDecimalCents = (
  val,
  removeZeroTail = false,
  isGstExempt = true
) => {
  if (!val) return 0;
  const scale = 1000;
  const gstRate = isGstExempt ? 1.0 : 1.1;

  // add GST, and change from dollars to cents
  let scaledVal = Math.round(val * gstRate * 100 * scale) / scale;

  if (scaledVal % 1 === 0 && removeZeroTail) {
    return scaledVal.toFixed();
  }

  scaledVal = scaledVal.toFixed(4);

  // if last decimal place is a '0', remove and return
  if (scaledVal.slice(-1) === '0') {
    return scaledVal.slice(0, -1);
  }

  return scaledVal;
};

export const roundToDecimalGST = (val, removeZeroTail = false) => {
  return roundToDecimal(val, removeZeroTail, false);
};

export const roundToDecimal = (
  val,
  removeZeroTail = false,
  isGstExempt = true
) => {
  const gstRate = isGstExempt ? 1.0 : 1.1;
  val = val ? val * gstRate : 0;

  const haveZeroIndecimalPlace = val % 1 === 0;

  return haveZeroIndecimalPlace && removeZeroTail
    ? val.toFixed()
    : val.toFixed(2);
};

export const maskSiteIdentifier = siteIdentifier => {
  if (!siteIdentifier) return '';
  const chars = siteIdentifier.split('');
  const maskedChars = chars.map((char, index) => {
    if (index > 3 && index < 8) {
      return '*';
    }
    return char;
  });
  return maskedChars;
};

export function titleCase(s) {
  return s && s[0].toUpperCase() + s.toLowerCase().slice(1);
}

export function getBrandImage(imageName = '', fallback = 'banner.jpeg') {
  const brand = titleCase(process.env.REACT_APP_BRAND_CODE);
  const partner = process.env.REACT_APP_PARTNER_BRAND_CODE
    ? titleCase(process.env.REACT_APP_PARTNER_BRAND_CODE)
    : '';
  const dirName = `${brand}${partner}`;

  try {
    return require(`App/themes/${dirName}/images/${imageName}`);
    // do stuff
  } catch (ex) {
    return require(`App/themes/${dirName}/images/${fallback}`);
  }
}

export function getAddressLineOneFromAddress(address) {
  if (isEmpty(address)) return '';

  // if Address already has a line one, return it
  if (address.unstructured_line_1) return address.unstructured_line_1;

  // Otherwise, let's make one from the components of the address object
  const apartment = [address.ApartmentType, address.ApartmentNumber];
  const streetAddress = [
    address.StreetNumber,
    address.StreetName,
    address.StreetType,
  ];
  let fullAddress = apartment
    .filter(ele => ele)
    .join(' ')
    .trim();
  if (fullAddress) {
    fullAddress += ', ';
  }
  return (fullAddress += streetAddress.filter(ele => ele).join(' '));
}

export const getFuelType = services => {
  const hasPowerPlan = services.find(
    s => s.productType === PRODUCT.ELECTRICITY
  );
  const hasGasPlan = services.find(s => s.productType === PRODUCT.GAS);
  if (hasPowerPlan && hasGasPlan) return 'D';
  if (hasPowerPlan) return 'E';
  if (hasGasPlan) return 'G';
  return '';
};

export const getReferFromCookie = () => {
  // FF disabled, then no cookie
  if (!referAFriend) return null;

  // Check if the cookie exists
  return cookie.get('refer') ? JSON.parse(cookie.get('refer')).campaign : null;
};

export const formatCurrency = (value, showDecimals = false) => {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: showDecimals ? 2 : 0,
    maximumFractionDigits: showDecimals ? 2 : 0,
  }).format(value);
};

export const getTangoBillFrequency = (fuel, normalizedFrequency) => {
  if (fuel === PRODUCT.ELECTRICITY)
    return TANGO_POWER_BILL_CYCLE_CODES[normalizedFrequency];

  if (fuel === PRODUCT.GAS)
    return TANGO_GAS_BILL_CYCLE_CODES[normalizedFrequency];
};
